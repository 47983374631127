import axios from 'axios';

import {baseUrl} from '@/js/util.js'

// 新增修改项目大事记
export const AddProjectBigEvent=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/AddProjectBigEvent`,
        data:datas
    })
}

// 删除项目大事记
export const DeleteProjectBigEvent=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/DeleteProjectBigEvent`,
        params:datas
    })
}

// 获取项目大事记列表
export const GetProjectBigEventList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/GetProjectBigEventList`,
        params:datas
    })
}

// 获取项目大事记详情
export const GetProjectBigEvent=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ProjectDescription/GetProjectBigEvent`,
        params:datas
    })
}